import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PanelColumnsPipe } from './panel-columns.pipe';
import { IsCategoryDisabledPipe } from './is-category-disabled.pipe';
import { PanelWidthPipe } from './panel-width.pipe';
import { GetUaTypePipe } from './get-ua-type.pipe';
import { AreUaFiltersAppliedPipe } from './are-ua-filters-applied.pipe';
import { IsInArrayPipe } from './is-in-array.pipe';
import { IsNotEmptyArrayPipe } from './is-not-empty-array.pipe';
import { GetCabinColorPipe } from './get-cabin-color.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { GetDetailNamePipe } from './get-detail-name.pipe';
import { IsObjInArrayPipe } from './is-obj-in-array.pipe';
import { AsLuxonDatePipe } from './as-luxon-date.pipe';
import { UtaVersionPipe } from './uta-version.pipe';
import { GetPropByIdPipe } from './get-prop-by-id.pipe';
import { GetNameByCodePipe } from './get-name-by-code.pipe';
import { AreFiltersAppliedPipe } from './are-filters-applied.pipe';
import { KeywordFilterPipe } from './keyword-filter.pipe';
import { NumericSuffixDisplayPipe } from './numeric-suffix-display.pipe';
import { MultiValuePlaceholderPipe } from './multi-placeholder.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { HistoryDetailsFormatPipe } from './history-details-formatting-pipe';
import { GetCabinNamePipe } from './get-cabin-name.pipe';
import { GetSubfleetNamePipe } from './get-subfleet-name.pipe';
import { GetAirportNamePipe } from './get-airport-name.pipe';
import { FormatMediaDatePipe } from './format-media-date.pipe';
import { IsCabinTargetedPipe } from './is-cabin-targeted.pipe';
import { GetBrandNamesPipe } from './get-brand-names-pipe';
import { GetCategoryNamesPipe } from './get-category-names.pipe';

const sharedPipes = [
  AreFiltersAppliedPipe,
  AreUaFiltersAppliedPipe,
  AsLuxonDatePipe,
  GetCabinColorPipe,
  GetDetailNamePipe,
  GetPropByIdPipe,
  GetNameByCodePipe,
  GetUaTypePipe,
  NumericSuffixDisplayPipe,
  PanelColumnsPipe,
  IsCategoryDisabledPipe,
  IsInArrayPipe,
  IsNotEmptyArrayPipe,
  IsObjInArrayPipe,
  PanelWidthPipe,
  SafeHtmlPipe,
  UtaVersionPipe,
  KeywordFilterPipe,
  MultiValuePlaceholderPipe,
  SafeUrlPipe,
  HistoryDetailsFormatPipe,
  GetCabinNamePipe,
  GetSubfleetNamePipe,
  GetAirportNamePipe,
  FormatMediaDatePipe,
  IsCabinTargetedPipe,
  GetBrandNamesPipe,
  GetCategoryNamesPipe,
];

@NgModule({
  declarations: [...sharedPipes,],
  exports: [...sharedPipes],
  imports: [
    CommonModule
  ]
})
export class SharedPipesModule { }
